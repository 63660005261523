import { GetStaticPaths, NextPage } from 'next';
import { getStory } from 'storyblok/storyblok';
import { ISbStoryData } from '@storyblok/react';
import useStoryblok from 'storyblok/storyblok-hook';
import { ParsedUrlQuery } from 'querystring';
import { getValidationTime, isProd, isStg } from '@lib/utils';
import { Homepage } from '../components/storyblok/Homepage/Homepage';

interface LandingPageProps {
  story: ISbStoryData;
}

interface LandingPageParams extends ParsedUrlQuery {
  handle?: string;
}

const LandingPage: NextPage<LandingPageProps> = ({ story }) => {
  const storyHook = useStoryblok(story);
  if (!storyHook?.content) return <></>;
  return <Homepage content={storyHook.content} />;
};

export const getStaticPaths: GetStaticPaths<LandingPageParams> = async (context) => {
  if (!isProd() && !isStg()) {
    return {
      fallback: 'blocking',
      paths: [],
    };
  }
  const { data } = await getStory('?starts_with=landingpages&per_page=100/');
  const paths: { params: { handle: string }; locale: string }[] = [];

  for (const locale of context.locales ?? ['de']) {
    paths.push(
      ...data.stories.map(({ slug }: { slug: string }) => ({ locale, params: { handle: slug } })),
    );
  }

  return {
    fallback: true,
    paths,
  };
};

export async function getStaticProps<T extends { params: { handle: string }; locale: string }>(
  context: T,
) {
  const slug = context.params?.handle;
  const { data } = await getStory(``, context.locale, {
    starts_with: 'landingpages',
    per_page: 100,
  });

  if (!data) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      story: data?.stories.find((s: any) => s.slug === slug) ?? false,
    },
    revalidate: getValidationTime(),
  };
}

export default LandingPage;
